$primary-color         : rgba(204, 89, 161, 1);
$primary-color-alpha2  : rgba(204, 89, 161, 0.2);
$primary-color-alpha5  : rgba(204, 89, 161, 0.9);
$secondary-color       : rgba(67, 139, 202, 1);
$secondary-color-alpha5: rgba(67, 139, 202, 0.2);
$jetdigital-yellow     :rgba(246, 169, 30, 1);
$creme-white           : rgba(253, 249, 237, 1);

body {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
	font-family: Roboto, sans-serif;
}

.main__Logo {
	position : absolute;
	top      : 20px;
	left     : 30px;
	max-width: 200px;
	z-index  : 2;

	@media (max-width: 768px) {
		max-width: 150px;
		left: 30%;
		top: 20%;
	}
}
.schedule__Logo {
	margin: 0 100px 0 auto;
	max-width: 200px;
	@media (max-width: 768px) {
		max-width: 150px;
		margin: 0 auto 0 auto;
	}
}
.calendly-inline-widget{
	
	margin-top: -100px;
	@media (max-width: 768px) {
		margin-top: 0px;
		margin-left: 15px;
		margin-right: 15px;
	}
}
.schedule__Container{
	background: url("../assets/imgs/bg_schedule.jpg") right top no-repeat;
	background-size: cover;
	height: 100vh;
	@media (max-width: 768px) {
		height: calc(100vh * 1.1);
	}
}
.first-impression-container {
	position        : relative;
	top             : 0;
	left            : 0;
	margin          : 0;
	padding         : 0;
	background-color: $primary-color;
	transition      : ease-in;

}

.main__Image {
	width  : 100vw;
	height : 800px;
	max-height : 800px;
	margin : 0;
	padding: 0;
	opacity: 0.8;
	overflow: hidden;
	.child__Image {
		width              : 100%;
		height             : 100%;
		background-color   : black;
		background-position: center;
		background-size    : cover;
		transition         : all 20s;

	}

	&:hover .child__Image {
		transform: scale(1.3);
	}



}

.video-title {
	font-size  : 30px;
	color      : white;
	font-weight: bolder;
	position   : absolute;
	text-align : center;
	top        : 40%;
	left       : 0;
	width      : 100vw;
	@media (max-width: 768px) {
		background-color: rgba(0,0,0,0.2);
		padding: 2px;
		
	}
}

.see-more__button {
	font-size  : 30px;
	color      : white !important;
	font-weight: bolder;
	position   : absolute !important;
	text-align : center;
	top        : 60%;
	left       : calc(50vw - 25px);
	min-width  : 50px;
	min-height : 50px;
	max-width  : 50px;
	max-height : 50px;
	transition : ease-in 500ms !important;
	opacity    : 0;
	visibility : hidden;
	background : none !important;

	&.active {
		opacity   : 1;
		visibility: visible;
	}

	&hover {
		background: none !important;
	}

	@media (max-width: 768px) {
		top: 70%;
	}

	&.impressionOne {
		left: calc(50vw - 155px) !important;
		@media (max-width: 768px) {
			left: calc(50vw - 70px) !important;
			top: -20px;
		}
		
	}
}

.impressionOne {
	background: linear-gradient(90deg, $primary-color-alpha2, $secondary-color-alpha5);
	height    : 100vh;
	display   : block;
	
	.impressionOne__grid-container {
		text-align   : left;
		padding-top  : 15%;
		padding-left : 10%;
		padding-right: 10%;
		.impressionOne__grid--lines{
			margin-bottom: 80px;
		}

		h1 {
			margin-top : 5%;
			margin-left: 15%;
			white-space: 20px;
			font-weight: bolder;
			// polygon(18% 10%, 100% 0%, 83% 86%, 4% 89%);
			transition : ease-in-out 300ms;

			span {
				color: $primary-color;
			}
		}

		img {
			align-items: top;

			border-radius: 8px;
			transition   : ease-in-out 300ms;

		}
	}

}

.Typist .Cursor {
	display: inline-block;
}

.Typist .Cursor--blinking {
	opacity  : 1;
	animation: blink 1s linear infinite;
}

@keyframes blink {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.inactiveLanBtn {
	display : none !important;
	position: fixed !important;
	right   : 20px;
	top     : 20px;
}

.activeLanBtn {
	position : fixed !important;
	right    : 20px;
	top      : 20px;
	max-width: 200px;
	z-index  : 2;
}

.impressionTwo {
	background: linear-gradient(90deg, white, $creme-white);
	height    : 100vh;
	display   : block;

	.impressionTwo__grid-container {
		text-align   : left;
		padding-top  : 5%;
		padding-left : 10%;
		padding-right: 10%;
		
		margin h1 {
			margin-top : 5%;
			margin-left: 15%;
			white-space: 20px;
			font-weight: bolder;
			// polygon(18% 10%, 100% 0%, 83% 86%, 4% 89%);
			transition : ease-in-out 300ms;

			span {
				color: $primary-color;
			}
		}

		img {
			border-radius: 8px;
			transition   : ease-in-out 300ms;

		}
	}

}
.mainText{
	font-size: 20px;
	margin-top: 60px;
	margin-bottom: 40px;
	line-height: 2;
}

//impressionTwo
.AutoSteps__container,
.MobileStepper__container {
	text-align: center;
}

.MobileStepper__container {
	padding-top: 5%;
}

.MuiMobileStepper-dot {
	background: none !important;
}

.MuiPaper-root.MuiMobileStepper-root.MuiMobileStepper-positionStatic.MuiPaper-elevation0 {
	background: none !important;
}

.MuiMobileStepper-dots {
	margin: 0 auto;
}

.MuiMobileStepper-dotActive {
	background-color: $jetdigital-yellow !important;
}

img.MuiCardMedia-root.imgSlider.MuiCardMedia-media.MuiCardMedia-img {
	max-height: 500px;
}

.imgText__container {
	position: relative;
}

.innerImg__text {
	position        : absolute;
	bottom          : 10%;
	color           : $creme-white;
	background-color: black;
	font-size       : 50px !important;
	padding         : 0 20px;
}

.see-more__button {
	position: absolute !important;
	right   : 40px;
	bottom  : 40px;
	z-index : 5;
}

.impressionFour {
	background: linear-gradient(90deg, #ded5ff, rgba(190, 190, 255,0.5));
	height    : 100vh;
	display   : block;
	
	.impressionFour__grid-container {
		text-align   : left;
		padding-top  : 5%;
		padding-left : 10%;
		padding-right: 10%;
		@media (max-width:768px){
			padding-left : 1%;
			padding-right: 1%;
			.hideMobile{
				display : none;
			}
		}
		margin h1 {
			margin-top : 5%;
			margin-left: 15%;
			white-space: 20px;
			font-weight: bolder;
			transition : ease-in-out 300ms;

			span {
				color: $primary-color;
			}
		}

		img {
			border-radius: 8px;
			transition   : ease-in-out 300ms;

		}
	}

}
.contactOptions {
	display: flex;
    padding-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    
	@media (max-width:768px){
		flex-wrap: wrap;
	}
}
// .MuiTooltip-tooltipPlacementTop{
// 	margin-bottom: -20px !important;
// 	margin-left: -20px !important;
// }
// .MuiTooltip-tooltipPlacementLeft{
// 	margin-top: 80px !important;
// 	margin-right: 20px !important;
// }
// .MuiTooltip-tooltipPlacementRight{
// 	margin-top: 80px !important;
// 	margin-left: -175px !important;
// }
.impressionText{
	@media (max-width: 768px) {
		font-size: 22px !important;
		margin-top: 15% !important;
    	margin-left: 0 !important;
	}
}